import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container,Grid,Typography,Card,CardActionArea,CardContent,CardMedia,Box} from '@material-ui/core';
import {
    Link,
  } from "react-router-dom";

const useStyles = makeStyles(theme => ({

    overallContainer:{
        maxWidth:"100%",
        background:"#F8F8F8 0% 0% no-repeat padding-box;"
    },
    gridWrapper:{
        maxWidth:"100%",
        background:"#F8F8F8 0% 0% no-repeat padding-box;"
    },
    subGrid:{
        padding: theme.spacing(2),
    },
    serviceConatiner:{
        maxWidth:"80%",
        display:"flex",
        flexWrap:"wrap",
        justifyContent:"space-evenly",
    },
    serviceCards:{
        width:"225px",
        // height:"328px",
        margin: "20px 0px",
        textAlign:"center"
    },
    cardImageWrapper:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
  }));

function ServiceCards(props) {

    const {containerProps} = props;
    const classes = useStyles();

    return (
        <Grid container  className = {classes.gridWrapper}  >
            <Grid item xs={12}  className = {classes.subGrid} align = "center"   >
                <Typography  variant="h2" color = "primary" >
                    Services
                </Typography>
            </Grid>
            <Grid item xs={12}   >
                <Container className={classes.serviceConatiner}  >
                    {
                        containerProps.services.map((servicesDetails,index) => (
                            <Card className={classes.serviceCards} key = {index} >
                                <CardActionArea component = {Link} to = {servicesDetails.post_content} >
                                    <Box className={classes.cardImageWrapper}  >
                                        <CardMedia
                                            style = {{"objectFit":"fill","width":"122px","height":"128px","margin":"10px"}}
                                            component="img"
                                            alt={servicesDetails.post_title}
                                            image={servicesDetails.guid}
                                            title="Contemplative Reptile"
                                        />
                                    </Box>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" style = {{'color':'black'}}  component="h2">
                                            {servicesDetails.post_title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p" style = {{'minHeight':'86px'}} >
                                            {servicesDetails.post_excerpt}
                                        </Typography>
                                        <Typography size="small" color="primary"  >
                                            To Know More
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        ))
                    }
                </Container>
            </Grid>
        </Grid>
    )
}

export default ServiceCards
