import React,{useState,useEffect,useContext} from 'react'
import Axios from 'axios';
import {LinkContext} from "../../App"
import Footer from '../Footer';
import CircularLoader from '../CircularLoader';
import TestimonialsCards from '../containers/TestimonialsCards';

function Testimonials(props) {
    const {selectedPage,totalPages,setOnloadLoader} = props;
    const pageId = selectedPage.object_id;
    const [loading, setLoading] = useState(false);
    const [testimonials,setTestimonial] = useState();
    const API = useContext(LinkContext);
    useEffect(() => {
        Axios.all([Axios.get(`${API.posts}?categories=4`)])
        .then(Axios.spread((resp1 ) => {
            setTestimonial(resp1.data);
            setOnloadLoader(true);
            setLoading(true);
            console.log(resp1.data);
            
        }))
        .catch(Axios.spread((err1 , err2 ) => {

        })) 
        return () => {
        }
      }, [API.pages,API.posts,setOnloadLoader,pageId]);
      let containerProps;
      if(loading)
      {
        document.title = "Customer Testimonials - Youtbooks"
          containerProps = {
              Testimonials:{

          },
          }
      }
    return (
        <>
            {
                loading?
                <>
                    <div style = {{'position':'relative','top':'43px'}} >
                        <TestimonialsCards containerProps = {testimonials} />
                        <Footer totalPages = {totalPages}  />
                    </div>
                </>
                :
                <CircularLoader />
            }
        </>    
    )
}

export default Testimonials

