import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box,Typography,Button} from '@material-ui/core';
import {
    Link,
  } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(theme => ({
    imageContainerStyle: {
      backgroundImage: containerProps => `url(${containerProps.desktopBannerImage})`,
      position:"relative",
      width:"100%",
      height:"660px",
      backgroundSize:"cover",
      backgroundRepeat :"no-repeat",
      display:"flex",
      alignItems:"center",
      [theme.breakpoints.down('md')]: {
        backgroundPosition: "50% 0%",
      },
      [theme.breakpoints.down('xs')]: {
        backgroundImage: containerProps => `url(${containerProps.mobileBannerImage})`,
        height:"85vh",
        backgroundSize:"cover",
        backgroundPosition: "0%",
      }
    },
    imageContainerWrapper:{
        left: "10%",
        width: "438px",
        position: "relative",
        color:"white",
        [theme.breakpoints.down('md')]: {
            left: "10%",
            width: "40%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "70%",
        }
    },
    header:{
        marginBottom:"15px",
        opacity:"86%",
        [theme.breakpoints.down('md')]: {
            fontSize:"1.8em",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize:"1em",
        }
    },
    content:{
        marginBottom:"15px",
        opacity:"76%",
        fontSize:"16px",
        [theme.breakpoints.down('md')]: {
            fontSize:"1em",
            marginBottom:"25px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize:"0.7em",
        }
    },
    button:{
        marginBottom:"15px",
        color:"white",
        fontSize:"13px"
    },
  }));

function HeroBanner(props) {

    const {containerProps} = props;
    const classes = useStyles(containerProps);

    return (
        <Box className = {classes.imageContainerStyle} >
            <Box  className = {classes.imageContainerWrapper} >
                <Typography  variant="h1" className = {classes.header} >
                    {containerProps.header}
                </Typography>
                <Typography className = {classes.content} >
                    {ReactHtmlParser(containerProps.content)}
                </Typography >
                <Button variant="contained" component = {Link} to = {containerProps.buttonUrl} color="secondary" className = {classes.button} >
                    {containerProps.buttonText}
                </Button>
            </Box>
        </Box>
    )
}

export default HeroBanner
