import React,{useState,useEffect,useContext} from 'react'
import Axios from 'axios';
import {LinkContext} from "../../App"
import HeroBanner from '../containers/HeroBanner';
import VideoContainer from '../containers/VideoContainer';
import ServiceCards from '../containers/ServiceCards';
import CarouselTestimonials from '../containers/CarouselTestimonials';
import ClientLogo from '../containers/ClientLogo';
import ReadyToWork from '../containers/ReadyToWork';
import ConnectWithUs from '../containers/ConnectWithUs';
import Footer from '../Footer';
import CircularLoader from '../CircularLoader';

function Home(props) {

    const {selectedPage,totalPages,setOnloadLoader} = props;
    const pageId = selectedPage.object_id;
    const [layoutObj, setLayoutObj] = useState({acf:{image:'',mobile_view_image:'',video_url:"",ready_image:""}});
    const [loading, setLoading] = useState(false);
    const [testimonials,setTestimonial] = useState();
    const API = useContext(LinkContext);
    console.log("Home")
    

    useEffect(() => {
        Axios.all([Axios.get(`${API.pages + pageId}`),Axios.get(`${API.posts}?categories=4`)])
        .then(Axios.spread((resp1,resp2 ) => {
            setLayoutObj(resp1.data);
            setTestimonial(resp2.data);
            setOnloadLoader(true);
            setLoading(true);
        }))
        .catch(Axios.spread((err1 , err2 ) => {

        })) 
        return () => {
        }
      }, [API.pages,API.posts,setOnloadLoader,pageId]);
      let containerProps;
      if(loading)
      {
          document.title = "Self- Publishing | Book design | cover design | eBook";
          containerProps = {
              heroBannerProps:{
                  header:layoutObj.acf.header,
                  content:layoutObj.acf.content,
                  buttonText:layoutObj.acf.button_text,
                  desktopBannerImage:layoutObj.acf.image,
                  mobileBannerImage:layoutObj.acf.mobile_view_image,
                  buttonUrl:layoutObj.acf.button_url
              },
              videoContainerProps:{
                  header:layoutObj.acf.video_header,
                  content1:layoutObj.acf.video_content_1,
                  content2:layoutObj.acf.video_content_2,
                  content3:layoutObj.acf.video_content_3,
                  videoUrl:layoutObj.acf.video_url
              },
              serviceCardsProps:{
                services:layoutObj.acf.add_services,
              },
              readyToWorkProps:{
                  header:layoutObj.acf.ready_header,
                  content1:layoutObj.acf.Ready_1,
                  content2:layoutObj.acf.ready_2,
                  content3:layoutObj.acf.Ready_3,
                  image:layoutObj.acf.ready_image
              },
              ClientLogoProps:{
                  header:layoutObj.acf.workedWith_header,
                  clientLogo:layoutObj.acf.client_image
              },
              connectWithUsprops:{
                    required:layoutObj.acf.connectWithUs_required,
                    header:layoutObj.acf.connectWithUs_header,
                    images:layoutObj.acf.connectWithUs_images
              },
          }
      }

    return (
        <>
            {
                loading?
                <>
                    <div style = {{'position':'relative','top':'64px'}} >
                        <HeroBanner containerProps = {containerProps.heroBannerProps} />
                        <VideoContainer containerProps = {containerProps.videoContainerProps} />
                        <ServiceCards containerProps = {containerProps.serviceCardsProps} />
                        <CarouselTestimonials containerProps = {testimonials}  />
                        <ReadyToWork containerProps = {containerProps.readyToWorkProps}  />
                        <ClientLogo containerProps = {containerProps.ClientLogoProps} />
                        <ConnectWithUs  containerProps = {containerProps.connectWithUsprops} />
                        <Footer totalPages = {totalPages}  />
                    </div>
                </>
                :
                <CircularLoader />
            }
        </>
    )
}

export default Home
