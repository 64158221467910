import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container,Box,Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const useStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        width: "90%",
        left:"5%",
        position:'relative',
  },

    overallConatiner:{
        paddingTop:"100px",
        minWidth:"100%",
        maxWidth:"100%",
        height:'calc(100vh - 70px)',
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('xs')]: {
        }
    },
    imageWrapper:{
        display:"flex",
        justifyContent:"space-around",
        alignItems:"center",
        flexWrap:"wrap",
        height:"100%",
    },
    bookImage:{
        width:"220px",
        height:"345px",
        margin:" 25px 50px",
        boxShadow:"0px 0px 10px #a2a2a2",
        cursor:"pointer"
    },
    fullBookImage:{
        width:"344px",
        height:"551px",
        zIndex:"5002",
        display:"flex",
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('xs')]: {
            width:"220px",
            height:"345px",
        }
       
    },
    bookArrowLeft:{
        color:"white",
        position:"absolute",
        top:"40%",
        color:"white",
        fontSize:"5em",
        zIndex:"5001",
        cursor:"pointer",
        userSelect:"none",
        left:"20px",
        [theme.breakpoints.down('xs')]: {
            left:"10px",
        }
    },
    bookArrowRight:{
        color:"white",
        position:"absolute",
        top:"40%",
        color:"white",
        fontSize:"5em",
        zIndex:"5001",
        cursor:"pointer",
        userSelect:"none",
        right:"20px",
        [theme.breakpoints.down('xs')]: {
            right:"10px",
        }
    },
    coverDullScreen:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        background:"rgba(0, 0, 0, 0.5)",
        position:"fixed",
        top:"0",
        left:0,
        width:"100%",
        height:"100%",
        zIndex:5000,
        display:"none",
    },
    menuContainer:{
        margin:"0% auto",
        width:"auto",
        paddingLeft:"30%",
        [theme.breakpoints.down('xs')]: {
            width:"90%",
            fontSize:"0.6em",
            paddingLeft:"0",
        }
    },
    
    
  }));

  

function WorkImages(props) {

    const {containerProps} = props;
  
    const [open, setOpen] = React.useState(false);
    const [fullScreenCoverImage, setFullScreenCoverImage] = React.useState('');
    const [selectedBookIndex, setSelectedBookIndex] = React.useState(0);
    const [value, setValue] = React.useState(0);
    var selectedWorkObj = containerProps[value];
    const classes = useStyles(selectedWorkObj);
    
    


    const handleClickOpen = (index) => {
     //setOpen(true);
     document.getElementById('fullScreenCover').style.cssText+= "display:flex;";
      setSelectedBookIndex(index);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const showSelectedBook = (flag,e) =>
    {
        
        e.preventDefault();
        e.stopPropagation();
        if(flag == 1 && selectedBookIndex!=0)
        {
           
            var count = selectedBookIndex-1;
            document.getElementById('fullScreenCoverImage').src =selectedWorkObj.acf.choose_work_images[count].guid;
            setSelectedBookIndex(count);
        }
        else if( flag == 2 &&  selectedBookIndex<selectedWorkObj.acf.choose_work_images.length-1)
        {
            var count = selectedBookIndex+1;
            document.getElementById('fullScreenCoverImage').src = selectedWorkObj.acf.choose_work_images[count].guid;
            setSelectedBookIndex(count);
        }
    }
    function closeCoverImage()
    {
        document.getElementById('fullScreenCover').style.cssText+= "display:none";
    }
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      

    return (
        <>
        {
            // (containerProps.required)?
            <div className = {classes.overallConatiner}  >
                {/* <Paper className={classes.root } > */}
                    <div style = {{textAlign:'center',width:"100%"}} >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        // indicatorColor="primary"
                        textColor="secondary"
                        centered
                        variant="scrollable"    
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        className = {classes.menuContainer}
                    >
                        {
                        containerProps.map((imageObj,index) => (
                            <Tab style = {{fontSize:"1em",}} label={imageObj.title.rendered.toLowerCase()} />
                            ))
                        }
                    </Tabs>
                    </div>
                {/* </Paper> */}
                <div className = {classes.imageWrapper} >
                    {
                        (selectedWorkObj.acf.required)?
                        selectedWorkObj.acf.choose_work_images.map((imageObj,index) => (
                            <img className={classes.bookImage} style = {( selectedWorkObj.title.rendered != "Cover Design" && window.innerWidth > 600)?{width:'400px',height:'500px'}:{}} onClick = { () => {
                                handleClickOpen(index); setFullScreenCoverImage(imageObj.guid)
                            }} key = {index} src = {imageObj.guid} alt = "work images" >
                                
                            </img>
                        ))
                        :
                        <></>
                    }

                    
                </div>
                

                <div className={classes.coverDullScreen} id = "fullScreenCover" onClick = {closeCoverImage}>
                 <div className={classes.bookArrowLeft} onClick = {(e) => {showSelectedBook(1,e)}}  >&#10216;</div>
                 <div style = {{display:"flex"}} ><img style = {( selectedWorkObj.title.rendered != "Cover Design" && window.innerWidth > 600)?{width:'400px',height:'500px'}:{}} className={classes.fullBookImage} id = "fullScreenCoverImage"  src = {fullScreenCoverImage} ></img></div>
                 <div className={classes.bookArrowRight} onClick = {(e) => {showSelectedBook(2,e)}} >&#10217;</div>
                </div>


                
                <Dialog
                open={open}
                onClose={handleClose}
                >
                    
                    <img style = {( selectedWorkObj.title.rendered != "Cover Design" )?{width:'400px',height:'500px'}:{}} className={classes.fullBookImage} alt = "full Work images" id = "fullScreenCoverImage"  src = {fullScreenCoverImage} >

                    </img>
                   
                </Dialog>
                
            </div>
            // :
            // <></>
        }
        </>
    )
}

export default WorkImages



