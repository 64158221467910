import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import { Container,Grid,Typography,Card,CardActionArea,CardContent,CardMedia,Box} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    overallWrapper:{
        Width:"100%",
    },
    container:{
        maxWidth:"80%",
        padding:"40px 0px",
        [theme.breakpoints.down('xs')]: {
            maxWidth:"85%",
        }
    },
    formHeaderWrapper:{
        textAlign:"center",
        paddingBottom:"30px",
        [theme.breakpoints.down('md')]: {

        }
    },
    formHeader:{
        [theme.breakpoints.down('xs')]: {
            fontSize:'0.9em'
        }
    },
    faqWrapper:{
        border:'solid 1px #cfcfcf',
        [theme.breakpoints.down('xs')]: {
           
        }
    },
    faqMenuWrapper:{
        display:'inline-block',
        verticalAlign:'top',
        width:'21%',
        
        [theme.breakpoints.down('md')]: {
            width:'36%',
        }
    },
    faqQuesionWrapper:{
        display:'inline-block',
        verticalAlign:'top',
        width:'79%',
        [theme.breakpoints.down('md')]: {
            width:'64%',
        },
       
    },
    faqMenu:{
        textAlign:'center',
        fontSize:'1.2em',
        height:'55px',
        fontWeight:'bold',
        lineHeight:'2.5',
        color:'black',
        cursor:'pointer',
        "&:hover": {
            // background: "#0073FF",
            // color:'white',
          },
          [theme.breakpoints.down('xs')]: {
            fontSize:'0.7em',
            lineHeight:'3',
            height:'35px',
        }
    },
    heading:{
        fontWeight:'bold',
        [theme.breakpoints.down('xs')]: {
            fontSize:'0.7em',
        }
    },
    answer:{
        opacity:'75%',
    },

  }));


function FaqContainer(props) {
    const {containerProps} = props;
    console.log(containerProps);
    const classes = useStyles();
    const [faqQuesAns, setFaqQuesAns] = useState(containerProps.faqArr[0].quesionAnsArr);
    var item = [{name:"eBook"},{name:"Paperback"},{name:"Cover design"}];

    function menuClickFunc(e)
    {
        for(var i = 0 ;  i < containerProps.faqArr.length; i++)
        {
            document.getElementById('faq_'+i).style.cssText+="background:none;color:black;";
        }
        e.target.style.cssText+="background:#0073FF;color:white;";
        console.log(e.target.id);
        var tempId = e.target.id;
        var selectedMenuId = tempId.split('_');
        console.log(selectedMenuId);
        setFaqQuesAns(containerProps.faqArr[selectedMenuId[1]].quesionAnsArr);
    }
    return (
        <>
            <div className = {classes.overallWrapper} >
                <Container  className = {classes.container} >
                    <Box className = {classes.formHeaderWrapper} >
                        <h1  className = {classes.formHeader} >FAQ ( Frequently Asked Quesion )</h1>
                    </Box>
                    <div  className = {classes.faqWrapper} >
                        <div  className = {classes.faqMenuWrapper} >
                        {
                            containerProps.faqArr.map((item,itemIndex) => 
                                <div className = {classes.faqMenu} id = {"faq_"+itemIndex} Style = {(itemIndex == 0)?'background:#0073FF;color:white;':''}  onClick = { (e) => menuClickFunc(e)} key = {itemIndex} >{item.menuName}</div>
                            )
                        }
                        </div>
                        <div  className = {classes.faqQuesionWrapper} >
                            {
                                faqQuesAns.map((item,itemIndex) => 
                                    <Accordion key = {itemIndex} >
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <Typography className={classes.heading}>{item.quesion}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Typography className={classes.answer}>
                                            {item.answer}
                                        </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default FaqContainer

