import React from 'react'
import Home from './Pages/Home'
import { makeStyles } from '@material-ui/core/styles';
import Services from './Pages/Services';
import Get_quote from './Pages/Get_quote';
import Works from './Pages/Works';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import Faq from './Pages/Faq';
import Testimonials from './Pages/Testimonials';

const useStyles = makeStyles(theme => ({
    overallWrapper:{
        filter:props => `${(props.blurEffect)?"blur(5px)":"none"}`,
    }
  }));

function Page(props) {

    const {selectedPage,totalPages,onLoadLoader,setOnloadLoader} = props;
    const classes = useStyles(props);
    let componentName;
    if(selectedPage.ID === 30)
    {
        componentName = <Home  onLoadLoader = {onLoadLoader} setOnloadLoader = {setOnloadLoader}  selectedPage = {selectedPage} totalPages = {totalPages} />
    }
    else if(selectedPage.ID === 26 )
    {
        componentName = <Get_quote onLoadLoader = {onLoadLoader} setOnloadLoader = {setOnloadLoader}  selectedPage = {selectedPage} totalPages = {totalPages} />
    }
    else if(selectedPage.menu_item_parent === "29" )
    {
        componentName = <Services onLoadLoader = {onLoadLoader} setOnloadLoader = {setOnloadLoader}  selectedPage = {selectedPage} totalPages = {totalPages} />
    }
    else if(selectedPage.ID === 28 )
    {
        componentName = <Works onLoadLoader = {onLoadLoader} setOnloadLoader = {setOnloadLoader}  selectedPage = {selectedPage} totalPages = {totalPages} />
    }
    else if(selectedPage.ID === 195 )
    {
        componentName = <AboutUs onLoadLoader = {onLoadLoader} setOnloadLoader = {setOnloadLoader}  selectedPage = {selectedPage} totalPages = {totalPages} />
    }
    else if(selectedPage.ID === 196 )
    {
        componentName = <ContactUs onLoadLoader = {onLoadLoader} setOnloadLoader = {setOnloadLoader}  selectedPage = {selectedPage} totalPages = {totalPages} />
    }
    else if(selectedPage.ID === 27 )
    {
        componentName = <Faq onLoadLoader = {onLoadLoader} setOnloadLoader = {setOnloadLoader}  selectedPage = {selectedPage} totalPages = {totalPages} />
    }
    else if(selectedPage.ID === 834 )
    {
        componentName = <Testimonials onLoadLoader = {onLoadLoader} setOnloadLoader = {setOnloadLoader}  selectedPage = {selectedPage} totalPages = {totalPages} />
    }
    else
    {
        componentName = ""
    } 

    console.log(selectedPage.ID);
    return (
        <>
            <div className = {classes.overallWrapper} >
                {componentName}
            </div>
        </>
    )
}

export default Page
