import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button,Container,Grid,Typography} from '@material-ui/core';
import {
    Link,
  } from "react-router-dom";
  import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(theme => ({

    container:{
        padding:'40px 0px',
        maxWidth:"90%",
        [theme.breakpoints.down('xs')]: {
            maxWidth:"80%",
        }
    },
    subGrid:{
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    gridContent:{
        fontSize:"18px",
        color:"rgba(112, 112, 112, 1)",
        padding:"17px 0px",
        [theme.breakpoints.down('xs')]: {
            padding:"12px 0px",
        }
    },
    gridImageWrapper:{
        display:"flex" ,
        justifyContent:"center",
        alignItems:"center",
    },
    gridImage:{
        backgroundSize:"100% 100%",
        backgroundRepeat:"norepeat",
        width: "450px",
        height: "310px",
        [theme.breakpoints.down('md')]: {
            width: "450px",
            height: "310px",
        },
        [theme.breakpoints.down('xs')]: {
            width: "93%",
            height: "211px",
        }
    },
    readMore:{
        color:"rgba(112, 112, 112, 1)",
        textDecoration:"underline",
        cursor:"pointer",
    },

  }));

function ReadyToWork(props) {

    const {containerProps} = props;
    const classes = useStyles();
    var str = containerProps.content1.split('<readmore>')[0];
    console.log(str);
    // if(str.length > 594)
    // {
    //     str = str.substr(0, 594);
    // }  
    const [description, setDescription] = useState(str);

    return (
        <Container className = {classes.container} >
            <Grid container  className = {classes.gridWrapper}  >
                <Grid item xs={12} md={5} className = {classes.subGrid}  >
                    <Typography  variant="h2" color = "primary" >
                        {containerProps.header}
                    </Typography>
                    <Typography className = {classes.gridContent} >
                        {ReactHtmlParser(description)}
                        {
                            (description.length == containerProps.content1.split('<readmore>')[0].length && containerProps.content1.indexOf('<readmore>')!==-1)?<span  onClick = { () => setDescription(containerProps.content1) } className = {classes.readMore} >Read More</span>:""
                        }
                        {
                            (description.length >= containerProps.content1.split('<readmore>')[0].length + 1 )?<span  onClick = { () => setDescription(str) }   className = {classes.readMore} >Read Less</span>:""
                        }
                    </Typography >
                    {/* <Typography className = {classes.gridContent} >
                        {containerProps.content2}
                    </Typography >
                    <Typography className = {classes.gridContent} >
                        {containerProps.content3}
                    </Typography > */}
                    <Typography className = {classes.gridContent} >
                        <Button component = {Link} to = "/get-quote" variant="contained" color="secondary" style = {{'color':'white'}} className = {classes.button} >
                            Get Your Quote
                        </Button>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7}  className = {classes.gridImageWrapper}   >
                    <img className = {classes.gridImage} src = {containerProps.image} alt = "ready to work"  />
                </Grid>
            </Grid>
        </Container>
    )
}

export default ReadyToWork

