import React,{useState,useEffect,useContext} from 'react'
import Axios from 'axios';
import {LinkContext} from "../../App"
import ServicesBanner from "../containers/ServicesBanner"
import WhatDoIGet from "../containers/WhatDoIGet"
import HowItWorks from "../containers/HowItWorks"
import ConnectWithUs from "../containers/ConnectWithUs"
import PricingTable from "../containers/PricingTable"
import PricingTableNew from "../containers/PricingTableNew"
import Footer from '../Footer';
import CircularLoader from '../CircularLoader';


function Services(props) {
    const {selectedPage,totalPages,setOnloadLoader} = props;
    setOnloadLoader(true);
    const API = useContext(LinkContext);
    const pageId = selectedPage.object_id;
    const [loading, setLoading] = useState(false);
    const [layoutObj, setLayoutObj] = useState();
  
    useEffect(() => {
       
        Axios.all([Axios.get(`${API.pages + pageId}`)])
        .then(Axios.spread((resp1,resp2 ) => {
            setLayoutObj(resp1.data);
            setOnloadLoader(true);
            setLoading(true);
           
        }))
        .catch(Axios.spread((err1 , err2 ) => {

        })) 
        return () => {
        }
      }, [API.pages,setOnloadLoader,pageId]);
      console.log(selectedPage);
      let containerProps;
      if(loading)
      {
        document.title = layoutObj.acf.heroImage__header+" – YouTbooks";
          if(layoutObj.acf.pricingTable_required)
          {
              var pricingTableArr = [];
              for(var i = 1 ; i <= layoutObj.acf.feature_count ; i ++)
              {
                    var obj = {};
                    obj.featureName = layoutObj.acf['feature_'+i].feature_name;
                    var planObj = {}
                    for(var j = 0 ; j <layoutObj.acf['feature_'+i].choose_plan.length ; j ++)
                    {
                        if(layoutObj.acf['feature_'+i].choose_plan[j] === "normal")
                        {
                            planObj.normal = true;
                        }
                        else if(layoutObj.acf['feature_'+i].choose_plan[j]  === "medium")
                        {
                            planObj.medium = true;
                        }
                        else if(layoutObj.acf['feature_'+i].choose_plan[j]  === "high")
                        {
                            planObj.high = true;
                        }
                    }
                    if(layoutObj.acf['feature_'+i].choose_plan.length === 0 )
                        obj.isSelectObj = {'normal':false,'medium':false,'high':false,};
                    else
                        obj.isSelectObj = planObj;
                    if(layoutObj.acf['feature_'+i].hasOwnProperty('add_text') && layoutObj.acf['feature_'+i].add_text != "")
                    {
                        var textArr = layoutObj.acf['feature_'+i].add_text.split(':');
                        obj.normalText = textArr[0];
                        obj.mediumText = textArr[1];
                        obj.highText = textArr[2];
                    }
                    pricingTableArr.push(obj);
              }
          }
          console.log(pricingTableArr);
          containerProps = {
              heroBannerProps:{
                  required:layoutObj.acf.serviceBanner_required,
                  header:layoutObj.acf.heroImage__header,
                  quesion:layoutObj.acf.serviceBanner_quesion,
                  content1:layoutObj.acf.serviceBanner_content1,
                  content2:layoutObj.acf.serviceBanner_content2,
                  rendMoreLink:layoutObj.acf.serviceBanner_read_more_link,
                  desktopBannerImage:layoutObj.acf.serviceBanner_hero_image,
              },
              whatDoIGetProps:{
                required:layoutObj.acf.whatDoIGet_required,
                header:layoutObj.acf.whatDoIGet_header,
                image:layoutObj.acf.whatDoIGet_image,
                points:layoutObj.acf.whatDoIGet_points,
            },
            howItWorksProps:{
                required:layoutObj.acf.howItWoks_required,
                header:layoutObj.acf.howItWoks_header,
                video:layoutObj.acf.howItWoks_video_url,
            },
            pricingTableProps:{
                required:layoutObj.acf.pricingTable_required,
                serviceName:selectedPage.title,
                featureArr:pricingTableArr,
                normalPlanName:layoutObj.acf.normal_plan_name,
                mediumPlanName:layoutObj.acf.medium_plan_name,
                highPlanName:layoutObj.acf.high_plan_name,
                normalPlanRate:layoutObj.acf.normal_plan_rate,
                mediumPlanRate:layoutObj.acf.medium_plan_rate,
                highPlanRate:layoutObj.acf.high_plan_rate,
            },
            connectWithUsprops:{
                required:layoutObj.acf.connectWithUs_required,
                header:layoutObj.acf.connectWithUs_header,
                images:layoutObj.acf.connectWithUs_images
            },
          }
      }
    return (
        <>
            {
                loading?
                <>
                     <div style = {{'position':'relative','top':'64px'}} >
                        <ServicesBanner containerProps = {containerProps.heroBannerProps} />
                        <HowItWorks containerProps = {containerProps.howItWorksProps}  />
                        <WhatDoIGet containerProps = {containerProps.whatDoIGetProps} />
                        {/* <PricingTableNew containerProps = {containerProps.pricingTableProps} /> */}
                        <PricingTable containerProps = {containerProps.pricingTableProps} />
                        <ConnectWithUs  containerProps = {containerProps.connectWithUsprops} />
                        <Footer totalPages = {totalPages}  />
                    </div>
                </>
                :
                <CircularLoader />
            }
        </>
    )
}

export default Services
