import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box,Container,Grid,Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    container:{
        maxWidth:"80%",
        paddingBottom:"40px",
        [theme.breakpoints.down('xs')]: {
        }
    },
    subGrid:{
        padding: theme.spacing(1),
    },
    connectWithUsImage:{
        // width:"20px",
        // height:"20px",
    },
    connectWithUsGrid:{
        cursor:"pointer",
        display:"flex" ,
        justifyContent:"center",
        alignItems:"center",
    },
    connectWithUsConatiner:{
        padding:"5px 0px"
    },
  }));

function ConnectWithUs(props) {

    const {containerProps} = props;
    const classes = useStyles();

    return (
        <>
        {
            (containerProps.required)?
            <Container className = {classes.container} >
                <Grid container >
                    <Grid item md={12} xs={12} className = {classes.subGrid} align = "center" >
                        <Box p = {3}>
                            <Typography  variant="h2" color = "primary"  >
                                {containerProps.header}
                            </Typography>
                        </Box>
                    </Grid>
                    {
                        containerProps.images.map((tile) => (
                            <Grid item md={3} xs={6} key = {tile.ID} >
                                <Grid container className={classes.connectWithUsConatiner} >
                                    <Grid container item xs = {12} className={classes.connectWithUsGrid} onClick = { () => {window.location.href = tile.post_content } }>
                                        <img src={tile.guid}   alt={tile.post_title} className={classes.connectWithUsImage}  />
                                        <Typography  >
                                            {tile.post_excerpt}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
            :
            <></>
        }
    </>
    )
}

export default ConnectWithUs

