import React,{useState,useEffect,useContext} from 'react'
import Axios from 'axios';
import {LinkContext} from "../../App"
import ConnectWithUs from '../containers/ConnectWithUs';
import Footer from '../Footer';
import CircularLoader from '../CircularLoader';
import ContactUsForm from '../containers/ContactUsForm';

function ContactUs(props) {
    const {selectedPage,totalPages,setOnloadLoader} = props;
    const pageId = selectedPage.object_id;
    const [layoutObj, setLayoutObj] = useState({acf:{image:'',mobile_view_image:'',video_url:"",ready_image:""}});
    const [loading, setLoading] = useState(false);
    const API = useContext(LinkContext);
    useEffect(() => {
        Axios.all([Axios.get(`${API.pages + pageId}`)])
        .then(Axios.spread((resp1,resp2 ) => {
            setLayoutObj(resp1.data);
            setOnloadLoader(true);
            setLoading(true);
        }))
        .catch(Axios.spread((err1 , err2 ) => {

        })) 
        return () => {
        }
      }, [API.pages,setOnloadLoader,pageId]);
      let containerProps;
      if(loading)
      {
        document.title = "Contact us – YouTbooks";
          containerProps = {
              connectWithUsprops:{
                    required:layoutObj.acf.connectWithUs_required,
                    header:layoutObj.acf.connectWithUs_header,
                    images:layoutObj.acf.connectWithUs_images
              },
              contactUsProps : {
                text:layoutObj.acf.text,
                email:layoutObj.acf.email_id,
              },
          }
      }
    return (
        <>
            {
                loading?
                <>
                    <div style = {{'position':'relative','top':'43px'}} >
                        <ContactUsForm  containerProps = {containerProps.contactUsProps} />
                        <ConnectWithUs  containerProps = {containerProps.connectWithUsprops} />
                        <Footer totalPages = {totalPages}  />
                    </div>
                </>
                :
                <CircularLoader />
            }
        </>    
    )
}

export default ContactUs

