import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container,Grid,Typography} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(theme => ({

    overallConatiner:{
        minWidth:"80%",
        transition:"1s all"
    },
    gridWrapper:{
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        }
    },
    subGrid:{
        padding: theme.spacing(2),
    },
    imgSubGrid:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    gridContent:{
        fontSize:"18px",
        color:"rgba(112, 112, 112, 1)",
        padding:"17px 0px",
        [theme.breakpoints.down('xs')]: {
            padding:"12px 0px",
        }
    },
     gridImage:{
        backgroundSize:"100% 100%",
        backgroundRepeat:"norepeat",
        height:"300px",
        width:"500px",
        [theme.breakpoints.down('md')]: {
            height:"300px",
            width:"400px",
        },
        [theme.breakpoints.down('xs')]: {
            height:"200px",
            width:"260px",
        }
    },
    readMore:{
        color:"rgba(112, 112, 112, 1)",
        textDecoration:"underline",
        cursor:"pointer",
    },
    
  }));

function VideoContainer(props) {

    const {containerProps} = props;
    const classes = useStyles();
    var str = containerProps.content1.split('<readmore>')[0];
    const [description, setDescription] = useState(str);

    return (
        <Container className = {classes.overallConatiner}  >
            <Grid container  className = {classes.gridWrapper}  >
                <Grid item xs={12} md={6} className = {classes.subGrid}  >
                    <Typography  variant="h2" color = "primary" >
                        {containerProps.header}
                    </Typography>
                    <Typography className = {classes.gridContent} >
                        {/* {ReactHtmlParser(containerProps.content1)} */}
                        {ReactHtmlParser(description)}
                        {
                            (description.length == containerProps.content1.split('<readmore>')[0].length && containerProps.content1.indexOf('<readmore>')!==-1)?<span  onClick = { () => setDescription(containerProps.content1) } className = {classes.readMore} >Read More</span>:""
                        }
                        {
                            (description.length >= containerProps.content1.split('<readmore>')[0].length + 1 )?<span  onClick = { () => setDescription(str) }   className = {classes.readMore} >Read Less</span>:""
                        }
                    </Typography >
                </Grid>
                <Grid item xs={12} md={6}  className = {classes.imgSubGrid}   >
                    {/* <img className = {classes.gridImage} src = {containerProps.videoUrl} alt = "" /> */}
                    <video className = {classes.gridImage} controls src = {containerProps.videoUrl} ></video>
                </Grid>
            </Grid>
        </Container>
    )
}

export default VideoContainer
