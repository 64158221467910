import React,{useState} from 'react'
import {
    Link,
    NavLink
  } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Box} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Brightness4Icon from '@material-ui/icons/Brightness4';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header:{
      background:theme.palette.primary,
      height:"64px"
  },
  toolBar:{
      height:"100%"
  },
  menuButton: {
    display:"none",
    [theme.breakpoints.down('md')]: {
        display:"block"
     }
  },
  logoWrapper:{
    flexGrow:"1",
    cursor:"pointer",
  },
  logo:{
    marginLeft:"34px",
    // width:"200px",
    // height:"30px",
    [theme.breakpoints.down('xs')]: {
      backgroundSize:"100% 100%",
      backgroundRepeat :"no-repeat",
    //   width:"127px",
    //   height:"19px",
      marginLeft:"19px",
      marginTop:"5px"
  },
  },
  navbar:{
    [theme.breakpoints.down('md')]: {
       position:"absolute",
       width:mobileMenu => `${(mobileMenu)?"50%":"0%"}`,
       left:mobileMenu => `${(mobileMenu)?"50%":"100%"}`,
       top:"65px",
       overflow:"hidden",
       height:"calc(100vh - 50px)",
       transition:"left 0.3s",
       background:"rgba(0,0,0,0.8)",
    }
  },
  ul:{
    margin:0,
    padding:0,
    display:"flex",
    cursor:"pointer",
    textAlign:"center",
    [theme.breakpoints.down('md')]: {
        display:"block"
    },
  },
  li:{
    listStyle:"none",
    position:"relative",
    textDecoration:"none",
    padding:"3px 22px",
    [theme.breakpoints.down('md')]: {
        padding:"10px 22px",
    },
  },
  subUl:{
    position:"absolute",
    left:"-43px",
    top:"45px",
    background:"white",
    display: "none",
    padding:"0",
    zIndex:502,
    boxShadow:"0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    [theme.breakpoints.down('md')]: {
        position:"relative",
        display:"block",
        left:"0",
        top:"10px",
        background:"none",
        boxShadow:"0px 0px 10px rgba(0,0,0,0.7)",
    },
  },
  subLi:{
    listStyle:"none",
    textDecoration:"none",
    padding:"14px 35px",
    width:"150px",
    opacity:"65%",
    "&:hover":{
        opacity:"1"
    },
    [theme.breakpoints.down('md')]: {
        padding:"10px 0px",
        width:"100%",
       
    },
  },
  "@keyframes myEffect": {
    "0%": {
        padding:"0px 0px",
    },
    "100%": {
        padding:"10px 0px",
    }
  },
  anchorStyle:{
    color:"rgba(255, 255, 255, 0.7)",
    textDecoration:"none",
    "&:hover":{
        color:"white",
    },
  },
  anchorStyleSub:{
    color:"black",
    textDecoration:"none",
    [theme.breakpoints.down('md')]: {
        color:"rgba(255, 255, 255, 0.7)",
        "&:hover":{
            color:"white",
        },
    },
  },
  arrowDown:{
    position:'absolute',
    fontSize:"1.2rem",
    right:"0px",
    color:"rgba(255, 255, 255, 0.7)",
    [theme.breakpoints.down('md')]: {
        right:"6px",
    },
  },
  menuDullScreen:
  {
      position:"fixed",
      width:"100vw",
      height:"100vh",
      background:"rgb(0,0,0,0.2)",
      zIndex:"1000",
      display:mobileMenu => `${(mobileMenu)?"block":"none"}`,
      overflow:"hidden",
  },
  tranperentDullscreen:{
    position:"absolute",
    width:"100%",
    height:"100%",
    background:"rgb(0,0,0,0)",
    zIndex:"500",
    overflow:"hidden",
  },
}));

function Header(props) {
    console.log("header");
    const init = {
        flag:"false",
        action:""
    };
    const [tranperentDullscreenOpen,setTranperentDullscreen] = useState(false);
    const [subMenuOpen,setSubMenuOpen] = useState(init);
    const [mobileMenu,setMobileMenu] = useState(false);
    const {totalPageObj,darkModeSwitch,darkState,setBlurEffect,blurEffect,onLoadLoader} = props;
    const classes = useStyles(mobileMenu);
    function subMenuSelect(elem) {
        //elem.target.parentElement.parentElement.parentElement.children[0].style.color = "white";
    }
    return (
        <>
            {
                onLoadLoader?
                <div className={classes.root}>
                    {
                        (tranperentDullscreenOpen)?
                        <div className={classes.tranperentDullscreen} onClick = { () => {setTranperentDullscreen(false);setSubMenuOpen(!subMenuOpen)}} ></div>
                        :""

                    }
                    <AppBar position="fixed" className = {classes.header}  >
                        <Toolbar className = {classes.toolBar} >
                            <Box className = {classes.logoWrapper}   >
                                <img onClick = {() => {window.location.href = "/"} }  src = {totalPageObj.logo} alt = {"logoImage"} className = {classes.logo} ></img>
                            </Box>
                            <nav className={classes.navbar} >
                                <ul className={classes.ul} >
                                { 
                                    totalPageObj.items.map((item,index) =>
                                    <React.Fragment key = {index} >
                                    {
                                        <>
                                        {/* onMouseOver = {  (item.hasOwnProperty('child_items'))? (e) => {setSubMenuOpen({"flag":`${(subMenuOpen.flag === "true" )?false:true}`,"action":item.slug})}: () => {} } 
                                        onMouseLeave = {() => setSubMenuOpen({"flag":false,"action":item.slug})} 
                                        item.slug !== "about-us" && item.slug !== "contact-us" && 
                                        */}
                                        {
                                            (item.slug !== "faqq")?
                                            <li className={classes.li} onClick = { (item.hasOwnProperty('child_items'))? (e) => {setSubMenuOpen({"flag":`${(subMenuOpen.flag === "true" )?false:true}`,"action":item.slug})}: () => {setMobileMenu(false);setBlurEffect(false)} }  >
                                                <NavLink
                                                 exact={(item.slug === 'home'?true:false)}
                                                activeStyle = {{color:"white"}} to = {`/${(item.slug === "home")?"":item.slug }`}  className={classes.anchorStyle} onClick = { (item.hasOwnProperty('child_items'))? (e) => {e.preventDefault();setSubMenuOpen({"flag":`${(subMenuOpen.flag === "true" )?false:true}`,"action":item.slug});setTranperentDullscreen(true)}:() =>{setTranperentDullscreen(false);setSubMenuOpen(!subMenuOpen);} } >
                                                    {item.title}
                                                </NavLink>
                                                {
                                                    (item.hasOwnProperty('child_items'))?
                                                    <ArrowDropDownIcon className={classes.arrowDown}  />
                                                    :""
                                                }
                                                {
                                                    (item.hasOwnProperty('child_items'))?
                                                    <ul   className={classes.subUl} style = {(subMenuOpen.flag === "true" && subMenuOpen.action === item.slug )?{"display":"block"}:{"display":"none"}} >
                                                        {
                                                            item.child_items.map((submenuItem,subIndex) => 
                                                                    <li onClick = {(e) => {setSubMenuOpen(!subMenuOpen);setTranperentDullscreen(false);subMenuSelect(e);setMobileMenu(false);setBlurEffect(false)}} className={classes.subLi} key = {subIndex}  >
                                                                        <NavLink   to = {`/${(submenuItem.slug === "home")?"":`${item.slug}/${submenuItem.slug}` }`} className={classes.anchorStyleSub}  >
                                                                        {submenuItem.title}
                                                                        </NavLink>
                                                                    </li>
                                                            )
                                                        }
                                                    </ul>
                                                    :""
                                                }
                                            </li>
                                            :""
                                        }
                                        </>
                                    }
                                    </React.Fragment>
                                    )
                                }
                                {/* <Brightness4Icon onClick = { () => darkModeSwitch(!darkState) } /> */}
                                </ul>
                            </nav>
                            <IconButton edge="start" className={classes.menuButton} onClick = { () => {setMobileMenu(!mobileMenu);setBlurEffect(!blurEffect)}} color="inherit" aria-label="menu">
                                <MenuIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className = {classes.menuDullScreen} onClick = {() => {setMobileMenu(!mobileMenu);setBlurEffect(!blurEffect);setSubMenuOpen(!subMenuOpen)}} ></div>
                </div>
                :""
            }
        </>
    )
}

export default Header
