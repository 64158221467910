import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container,Box,Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles(theme => ({

    overallConatiner:{
        paddingTop:"70px",
        minWidth:"100%",
        maxWidth:"100%",
        height:'calc(100vh - 70px)',
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('xs')]: {
        }
    },
    imageWrapper:{
        display:"flex",
        justifyContent:"space-around",
        alignItems:"center",
        flexWrap:"wrap",
        height:"100%",
    },
    bookImage:{
        width:"220px",
        height:"345px",
        margin:" 25px 50px",
        boxShadow:"0px 0px 10px #a2a2a2",
        cursor:"pointer"
    },
    fullBookImage:{
        width:"344px",
        height:"551px",
        zIndex:"5002",
        display:"flex",
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('xs')]: {
            width:"220px",
            height:"345px",
        }
       
    },
    bookArrowLeft:{
        color:"white",
        position:"absolute",
        top:"40%",
        color:"white",
        fontSize:"5em",
        zIndex:"5001",
        cursor:"pointer",
        userSelect:"none",
        left:"20px",
        [theme.breakpoints.down('xs')]: {
            left:"10px",
        }
    },
    bookArrowRight:{
        color:"white",
        position:"absolute",
        top:"40%",
        color:"white",
        fontSize:"5em",
        zIndex:"5001",
        cursor:"pointer",
        userSelect:"none",
        right:"20px",
        [theme.breakpoints.down('xs')]: {
            right:"10px",
        }
    },
    coverDullScreen:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        background:"rgba(0, 0, 0, 0.5)",
        position:"fixed",
        top:"0",
        left:0,
        width:"100%",
        height:"100%",
        zIndex:5000,
        display:"none",
    },
    
  }));

function BookCoverGrid(props) {

    const {containerProps} = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [fullScreenCoverImage, setFullScreenCoverImage] = React.useState('');
    const [selectedBookIndex, setSelectedBookIndex] = React.useState(0);

    const handleClickOpen = (index) => {
     //setOpen(true);
     document.getElementById('fullScreenCover').style.cssText+= "display:flex;";
      setSelectedBookIndex(index);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const showSelectedBook = (flag,e) =>
    {
        
        e.preventDefault();
        e.stopPropagation();
        if(flag == 1 && selectedBookIndex!=0)
        {
           
            var count = selectedBookIndex-1;
            document.getElementById('fullScreenCoverImage').src = containerProps.bookCoverImages[count].guid;
            setSelectedBookIndex(count);
        }
        else if( flag == 2 &&  selectedBookIndex<containerProps.bookCoverImages.length-1)
        {
            var count = selectedBookIndex+1;
            document.getElementById('fullScreenCoverImage').src = containerProps.bookCoverImages[count].guid;
            setSelectedBookIndex(count);
        }
    }
    function closeCoverImage()
    {
        document.getElementById('fullScreenCover').style.cssText+= "display:none";
    }

    return (
        <>
        {
            (containerProps.required)?
            <div className = {classes.overallConatiner}  >
                <div className = {classes.imageWrapper} >
                    {
                        containerProps.bookCoverImages.map((imageObj,index) => (
                            <img className={classes.bookImage} alt = "Work images" onClick = { () => {
                                handleClickOpen(index); setFullScreenCoverImage(imageObj.guid)
                            }} key = {index} src = {imageObj.guid} >
                                
                            </img>
                        ))
                    }
                    {/* <img className={classes.bookImage} src = "https://localhost/wordpress/wp-content/uploads/2020/08/action-thriller-book-cover-design-template-3675ae3e3ac7ee095fc793ab61b812cc_screen.jpg" >
                    
                    </img>
                    <img className={classes.bookImage} src = "https://localhost/wordpress/wp-content/uploads/2020/08/Night_pb-eb-des2.jpg" >
                    
                    </img>
                    <img className={classes.bookImage} src = "https://localhost/wordpress/wp-content/uploads/2020/08/action-thriller-book-cover-design-template-3675ae3e3ac7ee095fc793ab61b812cc_screen.jpg" >
                    
                    </img>
                    <img className={classes.bookImage} src = "https://localhost/wordpress/wp-content/uploads/2020/08/Night_pb-eb-des2.jpg" >
                    
                    </img>

                    <img className={classes.bookImage} src = "https://localhost/wordpress/wp-content/uploads/2020/08/action-thriller-book-cover-design-template-3675ae3e3ac7ee095fc793ab61b812cc_screen.jpg" >
                    
                    </img>
                    <img className={classes.bookImage} src = "https://localhost/wordpress/wp-content/uploads/2020/08/Night_pb-eb-des2.jpg" >
                    
                    </img>
                    <img className={classes.bookImage} src = "https://localhost/wordpress/wp-content/uploads/2020/08/action-thriller-book-cover-design-template-3675ae3e3ac7ee095fc793ab61b812cc_screen.jpg" >
                    
                    </img> */}

                    
                </div>

                <div className={classes.coverDullScreen} id = "fullScreenCover" onClick = {closeCoverImage}>
                 <div className={classes.bookArrowLeft} onClick = {(e) => {showSelectedBook(1,e)}}  >&#10216;</div>
                 <div style = {{display:"flex"}} ><img alt = "Work images" className={classes.fullBookImage} id = "fullScreenCoverImage"  src = {fullScreenCoverImage} ></img></div>
                 <div className={classes.bookArrowRight} onClick = {(e) => {showSelectedBook(2,e)}} >&#10217;</div>
                </div>


                
                <Dialog
                open={open}
                onClose={handleClose}
                >
                    
                    <img  alt = "Work images" className={classes.fullBookImage} id = "fullScreenCoverImage"  src = {fullScreenCoverImage} >

                    </img>
                   
                </Dialog>
                
            </div>
            :
            <></>
        }
        </>
    )
}

export default BookCoverGrid


