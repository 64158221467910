import React,{useState} from 'react'
import Page from "./Page"
import {
  Route,
} from "react-router-dom";
import Header from './Header';


function RoutePage(props) {

    const {pageObj,blurEffect,setBlurEffect} = props;
    console.log("router");
    const [onLoadLoader,setOnloadLoader] = useState(true);
    return (
        <>
             <Header   setBlurEffect = {setBlurEffect} blurEffect = {blurEffect} onLoadLoader = {onLoadLoader} totalPageObj = {pageObj} />
            {pageObj.items.map((page, index) => 
                <React.Fragment key = {index} >
                    {
                        (page.hasOwnProperty('child_items')?
                        page.child_items.map((subPage,subIndex) => 
                            <Route
                                key={subPage.ID}
                                path={`/${page.slug}/${(subPage.slug)}`}
                                render={props => 
                                    <Page {...props} onLoadLoader = {onLoadLoader} setOnloadLoader = {setOnloadLoader} blurEffect = {blurEffect} selectedPage={subPage} totalPages = {pageObj}/>
                                }
                            />
                        )
                        :
                        <Route
                            key={page.ID}
                            path={`/${(page.slug === 'home'?"":page.slug)}`}
                            exact={(page.slug === 'home'?true:false)}
                            render={props => 
                                <Page {...props} onLoadLoader = {onLoadLoader} setOnloadLoader = {setOnloadLoader}  blurEffect = {blurEffect} selectedPage={page} totalPages = {pageObj}/>
                            }
                        />
                        )
                    }
                </React.Fragment>
            )}
        </>
    )
}

export default RoutePage
