import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container,Grid,Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    clientConatiner:{
        maxWidth:"80%",
        
        [theme.breakpoints.down('xs')]: {
        }
    },
    gridContainer:{
        justifyContent:"space-evenly",
    },
    subGrid:{
        padding: theme.spacing(2),
    },
    clientImagesGrid:{
        display:"flex",
        justifyContent:"space-evenly",
        alignItems:"center",
        padding: "15px 0px",
    },
    clientImages:{
        backgroundSize:"100% 100%",
        backgroundRepeat:"norepeat",
        width:"150px",
        height:"150px",
        [theme.breakpoints.down('xs')]: {
            width:"70px",
            height:"70px",
        }
    },
  }));

function ClientLogo(props) {

    const {containerProps} = props;
    const classes = useStyles();

    return (
        <Container className = {classes.clientConatiner} >
            <Grid container className = {classes.gridContainer} >
                <Grid item md={12} xs={12} className = {classes.subGrid} align = "center" >
                    <Typography  variant="h2" color = "primary"   >
                        {containerProps.header}
                    </Typography>
                </Grid>
                {
                    containerProps.clientLogo.map((tile) => (
                        <Grid item md={3} xs={6} key = {tile.ID} className = {classes.clientImagesGrid} >
                            <img src={tile.guid} alt={tile.post_title} className={classes.clientImages} />
                        </Grid>
                    ))
                }
            </Grid>
        </Container>
    )
}

export default ClientLogo
