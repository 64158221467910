import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box,Typography} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme) => ({

    overallWrapper:{
        display:"flex",
        background:"rgba(0, 115, 255, 1)",
        width:"100%",
        height:"auto",
        position:"relative",
        padding:"22px 0px",
    },
    imageContainerStyle: {
      backgroundImage: containerProps => `url(${containerProps.desktopBannerImage})`,
      width:"500px",
      height:"360px",
    //   boxShadow:"0px 0px 10px #a2a2a2",
      backgroundSize:"100% 100%",
      backgroundRepeat :"no-repeat",
      [theme.breakpoints.down('md')]: {
        display:"none"
      },
      [theme.breakpoints.down('xs')]: {
        display:"none"
      }
    },
    imageContainerWrapper:{
        // top: "130px",
        // left: "8%",
        display:"inline-block",
        verticalAlign:"top",
        width: "50%",
        padding:"50px 0px",
        paddingLeft: "100px",
        color:"white",
        [theme.breakpoints.down('md')]: {
            // top: "29%",
            paddingLeft: "10%",
            width: "85%",
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "10%",
            width: "85%",
        }
    },
    imageContainer:{
        verticalAlign:"top",
        width: "50%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        [theme.breakpoints.down('md')]: {
        display:"none"
        },
        [theme.breakpoints.down('xs')]: {
        display:"none"
        }
    },
    header:{
        marginBottom:"25px",
        fontWeight:"bold",
        fontSize:"40px",
        color:"white",
        [theme.breakpoints.down('md')]: {
            fontSize:"30px",
            marginBottom:"10px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize:"30px",
            marginBottom:"10px",
        }
    },
    quesion:{
        marginBottom:"15px",
        fontWeight:"bold",
        fontSize:"24px",
        [theme.breakpoints.down('md')]: {
            fontSize:"20px",
            marginBottom:"10px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize:"20px",
        }
    },
    content:{
        marginBottom:"15px",
        opacity:"96%",
        fontSize:"14px",
        [theme.breakpoints.down('md')]: {
            fontSize:"1em",
            fontSize:"10px",
            marginBottom:"10px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize:"0.9em",
            marginBottom:"10px",
        }
    },
    button:{
        marginBottom:"15px",
        color:"white",
        fontSize:"13px"
    },
    readMore:{
        color:"#7AFFF2",
        textDecoration:"underline",
        cursor:"pointer",
    },
  }));

function ServicesBanner(props) {

    const {containerProps} = props;
    const classes = useStyles(containerProps);
    var str = containerProps.content1.split('<readmore>')[0];
    const [description, setDescription] = useState(str);

    return (
        <Box className = {classes.overallWrapper} >
            <Box  className = {classes.imageContainerWrapper} >
                <Typography  variant="h2" className = {classes.header} >
                    {containerProps.header}
                </Typography>
                <Typography  variant="h4" className = {classes.quesion} >
                    {containerProps.quesion}
                </Typography>
                <Typography className = {classes.content} >
                    {ReactHtmlParser(description)}
                    {
                        (description.length === containerProps.content1.split('<readmore>')[0].length && containerProps.content1.indexOf('<readmore>')!==-1)?<span  onClick = { () => setDescription(containerProps.content1) } className = {classes.readMore} >Read More</span>:""
                    }
                    {
                        (description.length >= containerProps.content1.split('<readmore>')[0].length + 1 )?<span  onClick = { () => setDescription(str) }   className = {classes.readMore} >Read Less</span>:""
                    }
                </Typography >
                {/* <Typography className = {classes.content} >
                    {serviceDescription}&nbsp;&nbsp;&nbsp; 
                    {
                        (serviceDescription.length == 460 )?<a  onClick = { () => setServiceDescription(containerProps.content2) }  style = {{'color':'#7AFFF2',cursor:"pointer"}} >Read More</a>:""
                    }
                    {
                         (serviceDescription.length >= 461 )?<a  onClick = { () => setServiceDescription(str) }  style = {{'color':'#7AFFF2',cursor:"pointer"}} >Read Less</a>:""
                    }
                </Typography > */}
            </Box>
            <Box className = {classes.imageContainer} >
                <Box className = {classes.imageContainerStyle} >
            </Box>
            </Box>
        </Box>
    )
}
//

export default ServicesBanner
