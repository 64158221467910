import React,{useState,useEffect,useContext} from 'react'
import Axios from 'axios';
import {LinkContext} from "../../App"
import Footer from '../Footer';
import GetQuotesForm from "../containers/GetQuotesForm"

function Get_quote(props) {
    const {selectedPage,totalPages,setOnloadLoader} = props;
    setOnloadLoader(true);
    const API = useContext(LinkContext);
    const pageId = selectedPage.object_id;
    const [loading, setLoading] = useState(false);
    const [layoutObj, setLayoutObj] = useState();
    useEffect(() => {
        Axios.all([Axios.get(`${API.pages + pageId}`)])
        .then(Axios.spread((resp1,resp2 ) => {
            setLayoutObj(resp1.data);
            setOnloadLoader(true);
            setLoading(true);
        }))
        .catch(Axios.spread((err1 , err2 ) => {

        })) 
        return () => {
        }
      }, [API.pages,setOnloadLoader,pageId]);
      console.log("get quotes");
      let containerProps;
      if(loading)
      {
        document.title = "Get Quote - YouTbooks";
          containerProps = {
              
          }
      }
    return (
        <>
            <GetQuotesForm containerProps = {containerProps} />
        </>
    )
}

export default Get_quote
