import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container,Box,Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    overallConatiner:{
        padding:"50px 0px",
        paddingBottom:"0px",
        maxWidth:"75%",
        [theme.breakpoints.down('md')]: {
            maxWidth:"90%",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth:"100%",
        }
    },
    videoDiv:{
        padding:"50px 0px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    gridImage:{
        width:"80%",
        height:"80%",
    }
    
  }));

function HowItWorks(props) {

    const {containerProps} = props;
    const classes = useStyles();
    console.log(containerProps.video.url);

    return (
        <>
        {
            (containerProps.required)?
            <Container className = {classes.overallConatiner}  >
                <Typography  variant="h2" color = "primary" align = "center" >
                    {containerProps.header}
                </Typography>
                <Box item    className = {classes.videoDiv}   >
                    <img className = {classes.gridImage} src = {containerProps.video.url} alt = "video" />
                </Box>
            </Container>
            :
            <></>
        }
        </>
    )
}

export default HowItWorks

