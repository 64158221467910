import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import { Container,Grid,Typography,Card,CardActionArea,CardContent,CardMedia,Box,Button} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';


const useStyles = makeStyles(theme => ({
    overallWrapper:{
        Width:"100%",
    },
    container:{
        maxWidth:"80%",
        boxShadow:"0px 0px 5px #97a8d0",
        borderRadius:"5px",
        paddingTop:"5px",
        margin:"20px auto",
        [theme.breakpoints.down('xs')]: {
            maxWidth:"95%",
            boxShadow:"none",
        }
    },
    gridWrapper:{
        // boxShadow:"0px 0px 2px #a2a2a2",
        margin:"20px auto",
    },
    authorImage:{
        objectFit:"scale-down",
        width:"100px",
        height:"100px",
        margin:"0% auto",
        display:"flex",
        marginTop:"27px",
        [theme.breakpoints.down('xs')]: {
            margin:"0",
        }
    },
    formHeaderWrapper:{
        textAlign:"center",
        [theme.breakpoints.down('md')]: {
            marginTop:"30px",
        }
    },
    formHeader:{
        color:"#0073FF",
        paddingTop:"25px",
        [theme.breakpoints.down('xs')]: {
            paddingTop:"5px",
            
        }
    },
    paginationStyle:{
        display:"flex",
        justifyContent:"center",
        margin:"30px auto",
        paddingBottom:"12px",
    },
    videoContainer:{
        width:"90%",
        height:"300px",
        [theme.breakpoints.down('xs')]: {
            // width:"200px",
        }
    },
    contentWrapper_1:{
        display:"flex",
        justifyContent:"flex-end",
        alignItems:"center",
        height:"auto",
        boxShadow:"0px 0px 2px #a2a2a2",
        width:"90%",
        position:"relative",
        [theme.breakpoints.down('xs')]: {
            width:"100%",
            justifyContent:"center",
            marginTop:"80px",
        }
    },
    contentWrapper_2:{
        height:"auto",
        boxShadow:"0px 0px 2px #a2a2a2",
        marginLeft:"9%",
        display:"flex",
        justifyContent:"flex-end",
        alignItems:"center",
        width:"90%",
        position:"relative",
        [theme.breakpoints.down('xs')]: {
            width:"100%",
            justifyContent:"center",
            marginLeft:"0",
            marginTop:"80px",
        }
    },
    photoDiv_1:{
        position:"absolute",
        width:"160px",
        height:"160px",
        boxShadow:"0px 0px 5px #a2a2a2",
        right: "-80px  ",
        background:"white",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        [theme.breakpoints.down('xs')]: {
            right: "auto",
            top: "-80px",
        }
    },
    photoDiv_2:{
        position:"absolute",
        width:"160px",
        height:"160px",
        boxShadow:"0px 0px 5px #a2a2a2",
        left: "-80px  ",
        background:"white",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        [theme.breakpoints.down('xs')]: {
            left: "auto",
            top: "-80px",
        }
    },
    authorImage:{
        // objectFit:"scale-down",
        width:"95%",
        height:"95%",
    },
    content_1:{
        left:"0%",
        width:"92%",
        height:"100%",
        marginRight:"80px",
        minHeight:"250px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        [theme.breakpoints.down('xs')]: {
            width:"100%",
            marginRight:"0px",
            marginTop:"80px"
        }
    },
    content_2:{
        right:"0%",
        width:"92%",
        height:"100%",
        marginLeft:"80px",
        minHeight:"250px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        [theme.breakpoints.down('xs')]: {
            width:"100%",
            marginLeft:"0px",
            marginTop:"80px"
        }
    },
    gridWrapper_1:{
        padding:"20px 0px",
    },
    videoGrid:{
        padding:"10px",
        margin:"0px 10px",
        color:"black",
        cursor:"pointer",
        position:"relative",
        "&:hover":{
            background:"#609aca",
             color:"white"
        }
    },
    thumbnail:{
        width:"300px",
        height:"150px",
        
    },
    thumbnailText:{
        "&:hover":{
            color:"white"
        }
    },
    videoIcon:{
        width:"50px",
        height:"50px",
        position:"absolute",
        top:"100px",
        right:"18px",
    },

  }));

  


function TestimonialsCards(props) {
    const {containerProps} = props;
    console.log(containerProps);
    const [currentPage,setCurrentPage] = useState(1);
    const [postPerPage,setPostPerPage] = useState(3);
    var paginationCount = Math.ceil(containerProps.length/postPerPage);

    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    const curentPost = containerProps.slice(indexOfFirstPost,indexOfLastPost);
    var tempObj = {};
    var videoFirstIndex = "";
    for(var i = 0; i < containerProps.length;i++)
    {
        if(containerProps[i].acf.hasOwnProperty('upload_video') && containerProps[i].acf.upload_video !="")
        {
            tempObj = {'name':containerProps[i].title.rendered,'description':containerProps[i].acf.descriptions,'url':containerProps[i].acf.upload_video};
            videoFirstIndex = i;
            break;
        }
    }

    const [videoObj,setVideoObj] = useState(tempObj);

    function nextPage(event,page)
    {
        setCurrentPage(page);
    }

    function selectVideo(elem,index)
    {
        for(var i = 0; i < containerProps.length;i++)
        {
            if(containerProps[i].acf.hasOwnProperty('upload_video') && containerProps[i].acf.upload_video !="")
            {
                var elem = document.getElementById('videoGrid_'+i);
                elem.style.cssText+="background:none;color:black";
            }
        }
        var elem = document.getElementById('videoGrid_'+index);
        elem.style.cssText+="background:#609aca;color:white";
        setVideoObj({'name':containerProps[index].title.rendered,'description':containerProps[index].acf.descriptions,'url':containerProps[index].acf.upload_video});
        // document.getElementById('videoElem').src = containerProps[index].acf.upload_video;
        // document.getElementById('videoName').innerHTML = containerProps[index].title.rendered;
        // document.getElementById('videoDescription').innerHTML = ReactHtmlParser(containerProps[index].acf.descriptions);
    }

    


    const classes = useStyles();
    return (
        <>
            <div className = {classes.overallWrapper} >
                <Box className = {classes.formHeaderWrapper} >
                    <h1  className = {classes.formHeader} >Testimonials</h1>
                </Box>
                <Container  className = {classes.container} >
                {
                    curentPost.map((item,index) => (
                        <div> 
                             <Grid container className = {classes.gridWrapper}  spacing={0} >
                                    {
                                        (index%2 == 0)?
                                        <div className = {classes.contentWrapper_1} >
                                            <div  className = {classes.content_1} >
                                                <div>
                                                    <h2 style = {{color:"#656565",textAlign:"center"}}  >{item.title.rendered}</h2>
                                                    <div style = {{fontStyle:"normal",opacity:"0.6",textAlign:"center",padding:"0px 20px"}} >{ReactHtmlParser(item.acf.descriptions)}</div>
                                                </div>
                                            </div>
                                            <div className = {classes.photoDiv_1} >
                                                <img src = {item.acf.add_photo} className = {classes.authorImage} alt = "author image " />
                                            </div>
                                        </div>
                                        :
                                        <div className = {classes.contentWrapper_2} >
                                            <div  className = {classes.content_2} >
                                                <div>
                                                    <h2 style = {{color:"#656565",textAlign:"center"}}  >{item.title.rendered}</h2>
                                                    <div style = {{fontStyle:"normal",opacity:"0.6",textAlign:"center",padding:"0px 20px"}} >{ReactHtmlParser(item.acf.descriptions)}</div>
                                                </div>
                                            </div>
                                            <div className = {classes.photoDiv_2} >
                                                <img src = {item.acf.add_photo} className = {classes.authorImage} alt = "author image " />
                                            </div>
                                        </div>
                                    }
                                    
                                    {/* <Grid item xs={12} md={3} className = {classes.subGrid}   >
                                        <img src = {item.acf.add_photo} className = {classes.authorImage} alt = "author image " />
                                    </Grid>
                                    <Grid item xs={12} md={9} className = {classes.subGrid}   >
                                        <h2 style = {{color:"#656565"}}  >{item.title.rendered}</h2>
                                        {
                                            (item.acf.hasOwnProperty('upload_video'))?<video controls className = {classes.videoContainer} src = {item.acf.upload_video} ></video>:<></>
                                        }
                                        <div style = {{fontStyle:"normal",opacity:"0.6"}} >{ReactHtmlParser(item.acf.descriptions)}</div>
                                    </Grid> */}
                              </Grid>   
                        </div>
                    ))
                }
                 <Pagination   className = {classes.paginationStyle} onChange = {nextPage} count={paginationCount} color="primary" />
                </Container>
                <Box className = {classes.formHeaderWrapper} >
                    <h1  className = {classes.formHeader} >Video Testimonials</h1>
                </Box>
                <Container  className = {classes.container} >
                    <Grid container className = {classes.gridWrapper_1}  spacing={0} >
                        <Grid item xs={12} md={6} className = {classes.subGrid}   >
                            <h2 style = {{color:"#656565",textAlign:"center"}} id= "videoName"  >{videoObj.name}</h2>
                            <div id= "videoDescription"  style = {{fontStyle:"normal",opacity:"0.6",textAlign:"center",padding:"0px 20px"}} >{ReactHtmlParser(videoObj.description)}</div>
                        </Grid>
                        <Grid item xs={12} md={6} className = {classes.subGrid}   >
                            <video controls id = "videoElem" className = {classes.videoContainer}  src = {videoObj.url} ></video>
                        </Grid>
                        <Grid item xs={12} md={12} className = {classes.subGrid}   >
                            <h3 style = {{color:"#609aca"}} >More Videos</h3>
                            <div style = {{width:"100%",background:"#609aca",height:"2px"}} ></div>
                        </Grid>
                        <Grid container className = {classes.gridWrapper_1}   >
                            {
                                containerProps.map((item,index) => (
                                    <>
                                    {
                                        (item.acf.hasOwnProperty("upload_video") && item.acf.upload_video !="")?
                                            <div item xs={12} md={3} id = {`videoGrid_${index}`} style = {(index == videoFirstIndex)?{background:"#609aca",color:'white'}:{}} className = {classes.videoGrid}   onClick = {(e)=>{selectVideo(e,index)}} >
                                                <img className = {classes.thumbnail} src = {item.acf.video_thumbnail}  >
                                                </img>
                                                <h3 className = {classes.thumbnailText}  >{item.title.rendered}</h3>
                                                <h5 className = {classes.thumbnailText}  style = {{opacity:"0.5"}}  >{item.title.rendered}</h5>
                                                <img className = {classes.videoIcon} src = "https://www.youtbooks.com/wordpress/wp-content/uploads/2020/12/vidIcon1.png" />
                                            </div>
                                        :
                                        <></>
                                    }
                                    </>
                                ))
                            }
                        </Grid>
                    
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default TestimonialsCards

