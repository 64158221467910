import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container,Grid,Typography,Box,Button} from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ReactHtmlParser from 'react-html-parser';
import {
    Link,
  } from "react-router-dom";

const useStyles = makeStyles(theme => ({

    overallConatiner:{
        minWidth:"80%",
        [theme.breakpoints.down('xs')]: {
            minWidth:"70%",
        }
    },
    gridWrapper:{
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        }
    },
    subGrid:{
        padding: theme.spacing(2),
    },
    authorImage:{
        width:"75px",
        backgroundSize:"100% 100%",
        borderRadius:"50%"
    },
    testimonialDiv:{
        display:"inline-block",
        verticalAlign:"top",
        padding:"10px 10px",
        cursor:"pointer"
    },
    authorInfoWrapper:{
        padding:"8px"
    },
    descriptionGrid:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    button:{
        marginTop:"30px",
    },
    
  }));

function CarouselTestimonials(props) {

    const {containerProps} = props;
    const classes = useStyles();
    var str = containerProps[0].acf.author_description.split('<readmore>')[0];
    const [description, setDescription] = useState(str);
    function authorLink(link)
    {
       window.location.href = link;
    }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows:true,
    };
    return (
        <Container  className = {classes.overallConatiner}  >
            <Grid  className = {classes.gridWrapper} > 
                <Container  style = {{"maxWidth":"1000px"}} >
                    <Box align = "center">
                        <Typography  variant="h2" color = "primary" >
                            What Authors are saying
                        </Typography>
                    </Box>
                    <Slider {...settings}>
                        {
                            containerProps.map((testimonialDetails,index) => 
                            <Box key = {index} >
                                <Grid container className = {classes.gridWrapper} justify="center" spacing={2} >
                                    <Grid item xs={1} md={1} className = {classes.subGrid}   >
                                    </Grid>
                                    <Grid item xs={11} md={7} className = {classes.descriptionGrid}   >
                                        <Typography className = {classes.gridContent} p = {6} >
                                        {/* {ReactHtmlParser(description)}
                                        {
                                            (description.length === testimonialDetails.acf.author_description.split('<readmore>')[0].length && testimonialDetails.acf.author_description.indexOf('<readmore>')!==-1)?<span  onClick = { () => setDescription(testimonialDetails.acf.author_description) } className = {classes.readMore} >Read More</span>:""
                                        }
                                        {
                                            (description.length >= testimonialDetails.acf.author_description.split('<readmore>')[0].length + 1 )?<span  onClick = { () => setDescription(str) }   className = {classes.readMore} >Read Less</span>:""
                                        } */}
                                            {ReactHtmlParser(testimonialDetails.acf.author_description)}
                                        </Typography >
                                    </Grid>
                                    <Grid item xs={12} md={4} className = {classes.subGrid} align = "center"  >
                                            <div className={classes.authorInfoWrapper} >
                                            <img className = {classes.authorImage} 
                                                src = {testimonialDetails.acf.add_photo}
                                                width = "70px" 
                                                height = "70px"
                                                alt = "author photo"/>
                                            </div>
                                            <div  className={classes.authorInfoWrapper} >
                                                <Typography > {testimonialDetails.title.rendered}</Typography>
                                            </div>
                                            {/* <div className={classes.authorInfoWrapper} >   
                                                <div className={classes.testimonialDiv} onClick = {() => {authorLink(testimonialDetails.acf.author_facebook_link)} }  >
                                                    <img src = {'https://www.youtbooks.com/wordpress/icon-simple-facebook/'} alt = "" />
                                                </div >
                                                <div className={classes.testimonialDiv} onClick = {() => {authorLink(testimonialDetails.acf.author_twitter_link)} }   >
                                                    <img src = {'https://www.youtbooks.com/wordpress/icon-awesome-twitter/'} alt = ""/>
                                                </div>
                                                <div className={classes.testimonialDiv} onClick = {() => {authorLink(testimonialDetails.acf.author_linked_in)} }  >
                                                    <img src = {'https://www.youtbooks.com/wordpress/icon-awesome-linkedin-in/'} alt = "" />
                                                </div>
                                            </div> */}
                                    </Grid>
                                </Grid>  
                                <div style = {{textAlign:"center"}} >
                                    <Button variant="contained"  component = {Link} to = "/testimonial" color="primary" className = {classes.button} >
                                    Click here to see all our customer testimonials
                                    </Button>
                                </div>
                            </Box>
                            )
                        }
                    </Slider>
                </Container>
            </Grid>
        </Container>
    )
}

export default CarouselTestimonials

