import React,{useState,useEffect,useContext} from 'react'
import Axios from 'axios';
import {LinkContext} from "../../App"
import Footer from '../Footer';
import BookCoverGrid from '../containers/BookCoverGrid'
import CircularLoader from '../CircularLoader';
import WorkImages from '../containers/WorkImages';


function Works(props) {
    const {selectedPage,totalPages,setOnloadLoader} = props;
    setOnloadLoader(true);
    const API = useContext(LinkContext);
    const pageId = selectedPage.object_id;
    const [loading, setLoading] = useState(false);
    const [layoutObj, setLayoutObj] = useState();
  
    useEffect(() => {
        Axios.all([Axios.get(`${API.posts}?categories=5`)])
        .then(Axios.spread((resp1 ) => {
            setLayoutObj(resp1.data);
            setOnloadLoader(true);
            setLoading(true);
            
        }))
        .catch(Axios.spread((err1 , err2 ) => {

        })) 
        return () => {
        }
        }, [API.pages,API.posts,setOnloadLoader,pageId]);
      let containerProps;
      if(loading)
      {
        layoutObj.reverse();
        document.title = "Our work samples - Youtbooks"
          containerProps = {
              
          }
      }
    return (
        <>
            {
                loading?
                <>
                    <WorkImages containerProps = {layoutObj}/>
                    {/* <BookCoverGrid containerProps = {containerProps.bookCoverProps} /> */}
                </>
                :
                <CircularLoader />
            }
        </>
    )
}

export default Works
