import React,{useState,useEffect,useContext} from 'react'
import Axios from 'axios';
import {LinkContext} from "../../App"
import ConnectWithUs from '../containers/ConnectWithUs';
import Footer from '../Footer';
import CircularLoader from '../CircularLoader';
import FaqContainer from '../containers/FaqContainer';

function Faq(props) {
    const {selectedPage,totalPages,setOnloadLoader} = props;
    const pageId = selectedPage.object_id;
    const [layoutObj, setLayoutObj] = useState({acf:{image:'',mobile_view_image:'',video_url:"",ready_image:""}});
    const [loading, setLoading] = useState(false);
    const API = useContext(LinkContext);
    useEffect(() => {
        Axios.all([Axios.get(`${API.pages + pageId}`)])
        .then(Axios.spread((resp1,resp2 ) => {
            setLayoutObj(resp1.data);
            setOnloadLoader(true);
            setLoading(true);
        }))
        .catch(Axios.spread((err1 , err2 ) => {

        })) 
        return () => {
        }
      }, [API.pages,setOnloadLoader,pageId]);
      let containerProps;
      if(loading)
      {
        document.title = "FAQ – YouTbooks";
        var faqObjArr = [];
        for(var  i = 1 ; i <= layoutObj.acf.display_count ; i++)
        {
            faqObjArr.push(layoutObj.acf['faq-'+i]);
        }
      
        var faqTotalArr = [];
        for(var  i = 0 ; i < faqObjArr.length ; i++)
        {
            var faqObj = {};
            var headerSplit = faqObjArr[i].split('<--QuesionsAndAnswers-->');
            faqObj.menuName = headerSplit[0];
            var quesionSplit = headerSplit[1].split('<--Quesion-->');
            console.log(quesionSplit);
            var quesionAnsArr = [];
            for(var j = 0 ; j < quesionSplit.length ; j++)
            {
                var quesionAnsObj = {};
                var tempArr = quesionSplit[j].split('<--answer-->');
                quesionAnsObj.quesion = tempArr[0];
                quesionAnsObj.answer = tempArr[1];
                quesionAnsArr.push(quesionAnsObj);
            }
            faqObj.quesionAnsArr = quesionAnsArr;
            faqTotalArr.push(faqObj);
        }
        console.log(faqTotalArr);
          containerProps = {
              connectWithUsprops:{
                    required:layoutObj.acf.connectWithUs_required,
                    header:layoutObj.acf.connectWithUs_header,
                    images:layoutObj.acf.connectWithUs_images
              },
              faqContainerProps:{
                faqArr:faqTotalArr,
          },
          }
      }
    return (
        <>
            {
                loading?
                <>
                    <div style = {{'position':'relative','top':'43px'}} >
                        <FaqContainer containerProps = {containerProps.faqContainerProps} />
                        <ConnectWithUs  containerProps = {containerProps.connectWithUsprops} />
                        <Footer totalPages = {totalPages}  />
                    </div>
                </>
                :
                <CircularLoader />
            }
        </>    
    )
}

export default Faq

