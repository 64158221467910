import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles((theme) => ({
    overallWrapper:{
        padding:"40px 15px",
        [theme.breakpoints.down('xs')]: {
           // padding:"0px"
        }
    },
    cardPricingUl: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
      cardPricingLi:{
        padding: "8px 5px",
      },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    //padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
      //padding: "20px 25px",
      fontSize:"30px",
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(1),
  },
  button:{
    width:"100%",
    height:"45px",
    textAlign:"center",
    alignItems:"center",
    font:"Bold 18px/22px Fira Sans",
    color:"rgba(46, 21, 21, 1)",
    lineHeight:2.5,
    background:"transparent linear-gradient(182deg, rgba(35, 227, 246, 1) 0%, rgba(42, 127, 255, 1) 100%) 0% 0% no-repeat padding-box;",
},
}));



export default function Pricing(props) {
  const classes = useStyles();
  const {containerProps} = props;
  var tiers = "";
  if(containerProps.required)
  {
    var featureObj = {};
    var normalPlanArr = [];
    for(var i = 0 ; i < containerProps.featureArr.length ; i++)
    {
        if(containerProps.featureArr[i].isSelectObj.normal)
        {
            normalPlanArr.push(containerProps.featureArr[i].featureName);
        }
    }
    var mediumPlanArr = [];
    for(var i = 0 ; i < containerProps.featureArr.length ; i++)
    {
        if(containerProps.featureArr[i].isSelectObj.medium)
        {
            mediumPlanArr.push(containerProps.featureArr[i].featureName);
        }
    }
    var highPlanArr = [];
    for(var i = 0 ; i < containerProps.featureArr.length ; i++)
    {
        if(containerProps.featureArr[i].isSelectObj.high)
        {
            highPlanArr.push(containerProps.featureArr[i].featureName);
        }
    }
  console.log(containerProps.required)
var tiers = [
    {
      title: containerProps.normalPlanName,
      price: containerProps.normalPlanRate,
      description: normalPlanArr,
      buttonText: 'Sign up for free',
      buttonVariant: 'outlined',
    },
    {
        title: containerProps.highPlanName,
    //   subheader: 'Most popular',
      price: containerProps.highPlanRate,
      description: highPlanArr,
      buttonText: 'Get started',
      buttonVariant: 'contained',
    },
    {
        title: containerProps.mediumPlanName,
      price: containerProps.mediumPlanRate,
      description: mediumPlanArr,
      buttonText: 'Contact us',
      buttonVariant: 'outlined',
    },
  ];
  
  }
  return (
    <React.Fragment>
        {
            (containerProps.required)?
            <>
                {/* End hero unit */}
                <Container maxWidth="md" component="main" className={classes.overallWrapper} >
                    <Grid container spacing={3} alignItems="flex-end">
                    {tiers.map((tier,index) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid item key={tier.title} xs={12} sm={tier.title === 'Two cover concept' ? 12 : 6} md={4}>
                        <Card>
                            <CardHeader
                            title={tier.title}
                            subheader={tier.subheader}
                            titleTypographyProps={{ align: 'center' }}
                            subheaderTypographyProps={{ align: 'center' }}
                            action={tier.title === 'Multiple cover concept' ? <StarIcon /> : null}
                            className={classes.cardHeader}
                            />
                            <div className = {classes.button} style = {index === 0 ? {'background':"transparent linear-gradient(180deg, rgba(255, 143, 14, 1) 0%, rgba(255, 196, 0, 1) 100%) 0% 0% no-repeat padding-box" }: index === 1 ?{'background':"transparent linear-gradient(180deg, rgba(249, 106, 6, 1) 0%, rgba(255, 87, 0, 1) 100%) 0% 0% no-repeat padding-box"}:{} }  >
                                Order Now
                            </div>
                            <CardContent>
                            <div className={classes.cardPricing}>
                                <Typography component="h2" variant="h3" color="textPrimary">
                                ${tier.price}
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                
                                </Typography>
                            </div>
                            <ul className={classes.cardPricingUl} >
                                {tier.description.map((line) => (
                                <Typography component="li" className={classes.cardPricingLi} variant="subtitle1" align="center" key={line}>
                                    {line}
                                </Typography>
                                ))}
                            </ul>
                            </CardContent>
                            <CardActions>
                            {/* <Button fullWidth variant={tier.buttonVariant} color="primary">
                                {tier.buttonText}
                            </Button> */}
                            <div className = {classes.button} style = {index === 0 ? {'background':"transparent linear-gradient(180deg, rgba(255, 143, 14, 1) 0%, rgba(255, 196, 0, 1) 100%) 0% 0% no-repeat padding-box" }: index === 1 ?{'background':"transparent linear-gradient(180deg, rgba(249, 106, 6, 1) 0%, rgba(255, 87, 0, 1) 100%) 0% 0% no-repeat padding-box"}:{} }  >
                                Order Now
                            </div>
                            </CardActions>
                        </Card>
                        </Grid>
                    ))}
                    </Grid>
                </Container>
            </>
            :<></>
        }
    </React.Fragment>
  );
}