import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import { Container,Grid,Typography,Card,CardActionArea,CardContent,CardMedia,Box} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    overallWrapper:{
        Width:"100%",
        background:"#0073FF",
        color:"white"
    },
    container:{
        maxWidth:"80%",
        padding:"40px 0px",
        [theme.breakpoints.down('xs')]: {
            maxWidth:"85%",
        }
    },
    formHeaderWrapper:{
        textAlign:"center",
        [theme.breakpoints.down('md')]: {

        }
    },
    formHeader:{
        [theme.breakpoints.down('xs')]: {
            paddingTop:"5px",
        }
    },

  }));


function AboutUsConent(props) {
    const {containerProps} = props;
    console.log(containerProps);
    const classes = useStyles();
    return (
        <>
            <div className = {classes.overallWrapper} >
                <Container  className = {classes.container} >
                    <Box className = {classes.formHeaderWrapper} >
                        <h1  className = {classes.formHeader} >About Us</h1>
                    </Box>
                    <Typography>
                        {ReactHtmlParser(containerProps.content)}
                    </Typography>
                </Container>
            </div>
        </>
    )
}

export default AboutUsConent
