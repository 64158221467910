import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container,Grid,Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    overallConatiner:{
        padding:"20px 0px",
        maxWidth:"80%",
        [theme.breakpoints.down('md')]: {
            maxWidth:"90%",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth:"100%",
        }
    },
    gridWrapper:{
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        }
    },
    subGrid:{
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            paddingRight: theme.spacing(2),
        }
    },
    imgSubGrid:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    gridContent:{
        fontSize:"18px",
        color:"rgba(112, 112, 112, 1)",
        padding:"8px 0px",
        [theme.breakpoints.down('xs')]: {
            padding:"8px 0px",
        }
    },
     gridImage:{
        backgroundSize:"100% 100%",
        backgroundRepeat:"norepeat",
        height:"300px",
        width:"500px",
        [theme.breakpoints.down('md')]: {
            height:"300px",
            width:"400px",
        },
        [theme.breakpoints.down('xs')]: {
            height:"200px",
            width:"260px",
        }
    },
    
  }));

function WhatDoIGet(props) {

    const {containerProps} = props;
    const classes = useStyles();
    console.log(containerProps.points)

    return (
        <>
        {
            (containerProps.required)?
            <Container className = {classes.overallConatiner}  >
                <Typography  variant="h2" color = "primary" align = "center" >
                    {containerProps.header}
                </Typography>
                <Grid container  className = {classes.gridWrapper}  >
                    <Grid item md={6}  xs={12}  className = {classes.subGrid}  >
                        <ul  >
                            {
                                Object.keys(containerProps.points).map( (key,index) => 
                                    (containerProps.points[key] !== "")?
                                    <Typography  key = {index} className = {classes.gridContent} >
                                        <li>
                                            {containerProps.points[key]}
                                        </li>
                                    </Typography >
                                    :""
                                
                                )
                            }
                        </ul>
                    </Grid>
                    <Grid item  md={6} xs={12}   className = {classes.imgSubGrid}   >
                        <img className = {classes.gridImage} src = {containerProps.image} alt = "image" />
                    </Grid>
                </Grid>
            </Container>
            :<></>
        }
        </>
    )
}

export default WhatDoIGet
