import React from 'react'
import { Box,Grid,Container} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import {
    Link,
    NavLink
  } from "react-router-dom";

const useStyles = makeStyles(theme => ({

    footerWrapper:{
        Width:"100%",
        background:"#0073FF",
        color:"white"
    },
    footerLogo:{
        maxWidth:"70%",
        padding:"40px",
        paddingBottom:"10px",
        display:"flex" ,
        justifyContent:"center",
        alignItems:"center",
        [theme.breakpoints.down('md')]: {
            maxWidth:"80%",
            padding:"20px",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth:"90%",
            padding:"20px",
        }
    },
    footerMenu:{
        maxWidth:"90%",
        padding:"40px",
        [theme.breakpoints.down('md')]: {
            maxWidth:"90%",
            padding:"20px",
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth:"90%",
            paddingTop:"0px",
            padding:"20px",
        }
    },
    connectWithUsIcon:{
        padding:"5px 10px",
        cursor:"pointer",
    },
    connectWithUsContainer:{
        justifyContent:"left",
        [theme.breakpoints.down('xs')]: {
            justifyContent:"center",
        }
    },
    connectWithUsHeader:{
        padding:"15px 10px",
        fontWeight:"bold",
        fontSize:"1.2em",
        [theme.breakpoints.down('xs')]: {
            textAlign:"center",
        }
    },
    

}))

export default function Footer(props) {

    const {totalPages} = props;
    console.log(totalPages);
    const classes = useStyles();
    const showHeader = true;
    function footerMenuClick(link)
    {
       window.location.href = `/${link}`;
    }

    return (
        <React.Fragment >
            {
                showHeader?
                    <Box className = {classes.footerWrapper} >
                        <Container className = {classes.footerLogo} >
                            <img  src = {totalPages.logo} alt = ""/>
                        </Container>
                        <Container className = {classes.footerMenu} >
                            <Grid container  >
                                <Grid item xs={12} md = {4} >
                                    < Grid container  className = {classes.connectWithUsContainer} >
                                        <Grid item xs={12} className = {classes.connectWithUsHeader} >
                                                Connect with us     
                                        </Grid>
                                        <div>
                                            <img className = {classes.connectWithUsIcon}  onClick = { () => {window.location.href = 'https://www.facebook.com/YouTbooks'} }  src= "https://www.youtbooks.com/wordpress/icon-simple-facebook/" alt = "Facebook" />
                                            <img className = {classes.connectWithUsIcon} onClick = { () => {window.location.href = ''} } src= "https://www.youtbooks.com/wordpress/group-2055/" alt = "Youtube" />
                                            <img className = {classes.connectWithUsIcon} onClick = { () => {window.location.href = 'https://www.instagram.com/youtbooks/?hl=en'} } src= "https://www.youtbooks.com/wordpress/icon-ionic-logo-instagram/" alt = "instagram" />
                                            <img className = {classes.connectWithUsIcon} onClick = { () => {window.location.href = ''} } src= "https://www.youtbooks.com/wordpress/group-2056/" alt = "twitter" />
                                            <img className = {classes.connectWithUsIcon} onClick = { () => {window.location.href = 'https://www.linkedin.com/company/youtbooks-for-self-publishing-solutions/'} } src= "https://www.youtbooks.com/wordpress/icon-awesome-linkedin-in/" alt = "linkedin" />
                                        </div>
                                        <Grid item xs={12} className = {classes.connectWithUsHeader}  >
                                                Contact us    
                                        </Grid>
                                        <div>
                                            <img className = {classes.connectWithUsIcon} src= "https://www.youtbooks.com/wordpress/group-2057/" alt = "email" /><span style = {{verticalAlign: "top",display: "inline-block",padding: "6px 0px"}} >publishing@youtbooks.com</span>
                                        </div>
                                    </ Grid>   
                                </Grid>
                                <Grid item xs={7} md = {4} >
                                    < Grid container    >
                                        <Grid item xs={12} style = {{"padding":"15px 10px","fontWeight":"bold","fontSize":"1.2em"}} >
                                                Services
                                        </Grid>
                                        {
                                            totalPages.items.map((item,index) => 
                                                <React.Fragment key = {index} >
                                                { 
                                                    (item.hasOwnProperty('child_items'))?
                                                    <>
                                                        {
                                                            item.child_items.map((submenuItem,subIndex) => 
                                                                <Grid key = {subIndex} item xs={12} md= {6} style = {{"padding":"10px","cursor":"pointer",'fontSize':'14px'}}  >
                                                                    <Link style = {{'color':'white','textDecoration':'none'}}  to = {`/services/${submenuItem.slug}`}>{submenuItem.title}</Link>
                                                                </Grid>
                                                            )
                                                        }   
                                                    </>   
                                                    :
                                                    ""
                                                }
                                                </React.Fragment>
                                            )
                                        }
                                    </ Grid>   
                                </Grid>
                                <Grid item xs={5} md = {4}  >
                                    < Grid container    >
                                        <Grid item xs={12} style = {{"padding":"15px 10px","fontWeight":"bold","fontSize":"1.2em"}} >
                                            Company
                                        </Grid>
                                        {
                                            totalPages.items.map((item,index) => 
                                                <React.Fragment  key = {index} >
                                                { 
                                                    (item.title !== "Services" && item.title !== "FAQ"  )
                                                    ?
                                                    <Grid item xs={12} md= {4} style = {{"padding":"10px","cursor":"pointer",'fontSize':'14px'}}  >
                                                        <Link style = {{'color':'white','textDecoration':'none'}}  to = {`/${(item.slug === "home")?"":item.slug }`}>{item.title}</Link>
                                                    </Grid>
                                                    :
                                                    ""
                                                }
                                                </React.Fragment>
                                            )
                                        }
                                    </ Grid>   
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                :""
            }
        </React.Fragment>
    )
}
